.game {
    overflow: hidden;
    display: flex;
    background: bisque;
    flex: 1 0 auto;
    position: relative;
}
.startGameWrapper {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
