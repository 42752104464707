.header {
    height: 80px;
    display: flex;
    background: azure;
    flex-flow: row;
}

.logoWrapper {
    flex: 0 1 auto;
}

.headerLogo {
    height: 100%;
}

.counter {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button{
    height: 100%;
    display: flex;
    background: darkkhaki;
}
