.pasta {
    width: 100px;
    height: 100px;
    position: absolute;
    animation: 20s linear 0s;
    animation-fill-mode: forwards;
    z-index: 2;
    opacity: 1;
}

.pastaRemoving {
    transition: opacity 125ms 700ms;
    opacity: 0;
    z-index: initial;
}

.pastaPaused {
    pointer-events: none;
}

.pasta img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
